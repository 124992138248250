import React from 'react';
import "./details.css";
import { MdLocationPin } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";

function Details() {
  return (
    <>
    <div className='flexContainer'>
                <div className='box1'>
                    <h3>About Us</h3>
                    <hr style={{ width: "9%",marginLeft:"0",border:"1.5px solid red",marginTop:"-12px",borderRadius:"4px" }} />
                    <p>El-alpha tourism offers exciting customizable packages to spectacular destinations. El-alpha tourism aims to create a harmony of travellers and industry operatives and streamline the travel process with modern, cutting edge technologies.</p>
                    <p>Mon - Sat &nbsp; &nbsp; &nbsp; 09:00am - 06:00pm</p>
                </div>
                <div className='box2'>
                    <h3>Quick Links</h3>
                    <hr style={{ width: "9%",marginLeft:"0",border:"1.5px solid red",marginTop:"-12px",borderRadius:"4px" }} />
                    <div className='flex1'>
                        <div className='flexbox1'>
                            <p>Regions</p>
                            <hr style={{ border: "1px dotted grey" }} />
                            <p>Countries</p>
                            <hr style={{ border: "1px dotted grey" }} />
                            <p>Destinations</p>
                            <hr style={{ border: "1px dotted grey" }} />
                            <p>International Tours</p>
                            <hr style={{ border: "1px dotted grey" }} />
                        </div>
                        <div className='flexbox1' >
                            <p>Visa</p>
                            <hr style={{ border: "1px dotted grey" }} />
                            <p>Contact Us</p>
                            <hr style={{ border: "1px dotted grey" }} />
                            <p>FAQS</p>
                            <hr style={{ border: "1px dotted grey" }} />
                            <p>Terms & Conditions</p>
                            <hr style={{ border: "1px dotted grey" }} />
                        </div>
                    </div>
                </div>
                <div className='box3'>
                    <h3>Let's Talk</h3>
                    <hr style={{ width: "9%",marginLeft:"0",border:"1.5px solid red",marginTop:"-12px",borderRadius:"4px" }} />
                    <p>
                    <span style={{color:"red"}}><MdLocationPin /></span> #12-101/102, Venkateswara Nagar, Mall, Chinthapalli (M), Nalgonda (D), Telangana - 508 245
                    </p>
                    <p style={{color:"red"}}><FaPhone /> +91(0)8691298009</p>
                    <p style={{color:"red"}}><MdEmail /> info@elalphatourism.com</p>
                    <p style={{color:"red"}}> <BsWhatsapp /> +91 9553844524</p>
                </div>
            </div>
            <div style={{backgroundColor:"grey",padding:"15px 25px"}}>Copyright @ 2024 , El-Alpha Tourism</div>
            </>
  )
}

export default Details;


