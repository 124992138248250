import React from 'react';
import gridimg1 from "../Images/countries-images/russia.jpg";
import gridimg2 from "../Images/countries-images/china.jpg";
import gridimg3 from "../Images/countries-images/egypt.jpg";
import gridimg4 from "../Images/countries-images/france.jpg";
import gridimg5 from "../Images/countries-images/japan.jpg";
import gridimg6 from "../Images/countries-images/swizerland.jpg";
import gridimg7 from "../Images/countries-images/thailand.jpg";
import gridimg8 from "../Images/countries-images/turkey.jpg";
import gridimg9 from "../Images/countries-images/ukraine.jpg";
import "./grid.css"

function Grid() {
  return (
    <div>
        <div style={{marginLeft:"25px"}}>
          <p><span style={{fontSize:"40px"}} className='grey'>Best Tourist Countries around the world</span> <br/> Once in a lifetime tours, one click away!</p>
          <hr className='hrLine'/>
        </div>
        <div className='gridContainer'>
          <div className='gridItem'>
            <img src={gridimg1} alt='Russia' />
            <h3>Russia</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg2} alt='China' />
            <h3>China</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg3} alt='Egypt' />
            <h3>Egypt</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg4} alt='France' />
            <h3>France</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg5} alt='Japan' />
            <h3>Japan</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg6} alt='Switzerland' />
            <h3>Switzerland</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg7} alt='Thailand' />
            <h3>Thailand</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg8} alt='Turkey' />
            <h3>Turkey</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
          <div className='gridItem'>
            <img src={gridimg9} alt='Ukraine' />
            <h3>Ukraine</h3>
            <button className='abbtn'>About</button>
            <button className='pacbtn'>Packages</button>
          </div>
        </div>
        <hr style={{border:"2px solid darkGrey"}}/>
      </div>
  )
}

export default Grid;


