import React from 'react';
import "./aboutPage.css";
import Details from '../Home/details';
import AboutContent from './aboutContent';

function AboutPage() {
  return (
    <>
    <div className='aboutbc'>
      <div className="abcont">
        <h1>About Us</h1>
      </div>
    </div>
    <AboutContent/>
    <Details/>
    </>
  )
}

export default AboutPage;