import React from 'react';
import "./internationalTours.css";
import { LuCalendarDays } from "react-icons/lu";

function InternatinalTours({ image, title, description,price }) {
  return (
    <div>
        <div className="card">
            <div className="card-image" style={{ backgroundImage: `url(${image})` }}>
            <div className="best-seller">Best Seller</div>
                <div className="card-content">
                    <p>{title}</p>
                    <p><LuCalendarDays/> {description} | <span style={{color:"red"}}> &#8377; {price}</span> </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InternatinalTours;