// import "./navBar.css";
// import logo from "../Images/el-alfha_logo1.jpg";
// import { NavLink } from "react-router-dom";

// const NavBar = () => {


//     return (
//         <nav className="navbar">
//             <span className="logo-container">
//                 <img src={logo} alt="Logo" className="logo" />
//             </span>
//             <ul className="nav-links">
//                 <li><NavLink className="navNames" to="/">HOME</NavLink></li>
//                 <li><NavLink className="navNames" to="tours">Tours</NavLink></li>
//                 <li><NavLink className="navNames" to="/about">ABOUT</NavLink></li>
//                 {/* <li><NavLink className="navNames" to="services">SERVICES</NavLink></li> */}
//                 {/* <li><NavLink className="navNames" to="projects">PROJECTS</NavLink></li> */}
//                 <li><NavLink className="navNames" to="contactus">CONTACT US</NavLink></li>
//                 {/* <li><NavLink className="navNames" to="cart">CART</NavLink></li> */}
//                 <li><button><NavLink className="btnclr" to="my-account">SIGN IN</NavLink></button></li>
//             </ul>
//         </nav>
// )
// }
// export default NavBar;


import React, { useState } from 'react';
import './navBar.css';
import logo from '../Images/el-alfha_logo1.jpg';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <span className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </span>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <li><NavLink className="navNames" to="/">HOME</NavLink></li>
        {/* <li><NavLink className="navNames" to="tours">TOURS</NavLink></li> */}
        <li><NavLink className="navNames" to="/about">ABOUT</NavLink></li>
        <li><NavLink className="navNames" to="contactus">CONTACT US</NavLink></li>
        <li><button><NavLink className="btnclr" to="my-account">SIGN IN</NavLink></button></li>
      </ul>
    </nav>
  );
};

export default NavBar;


