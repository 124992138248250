import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';

const Carousel = ({ images }) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} >
            <img src={image.src} alt={`Slide ${index}`} className="carousel-image" />
            <div className="carousel-content">
              <h2>{image.title}</h2>
              <p>{image.description}</p>
              <button className="btnover">{image.button}</button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;


