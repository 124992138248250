import React from 'react';
import "./aboutContent.css"

function AboutContent() {
    return (
        <div className='abContainer'>
             <p><span style={{fontSize:"40px"}} className='grey'>Why Choose El-Alpha Tourism</span> <br/> Unlock the doors to your dream destination with El-Alpha Tourism!</p>
            <hr className='hrLine' />
            <p>El-alpha tourism has established itself as an <b>International Travel Company</b> offering finely crafted experiences to customers around the world. El-alpha tourism offers <b>all-inclusive tour packages</b> to dreamy destinations around the globe.<br />

                Over the years, El-alpha tourism has planned and executed countless tours, helping more than 5 lakh customers to explore their dream destinations. We have also introduced new, magnificent destinations to the Indian market and aim to add more exciting travel experiences across the world to our catalog.<br />

                Our primary aim is not only to increase sales but to craft memorable experiences in stunning destinations - designing itineraries covering multiple countries and facilitating the VISA process. With our presence across the destinations, we ensure a seamless travel experience. Our wide selection of tour packages and other travel services offer travelers a huge catalog of destinations and experiences to choose from. Our strength lies in <b>Group Tours</b> and since inception, we have successfully executed over 1000 group departures, serving more than 5 lakh travelers, including families, Corporate Groups, and FITs (Free Independent Travelers). We constantly strive to provide unforgettable experiences and value for money to our customers. With a major focus on traveler satisfaction, El-alpha tourism aims to reach new heights over the years.</p>
            <p><b>Book Group Tour Departures with El-alpha tourism</b></p>
            <p>Flexi budget and multi destination departures for groups of all sizes. We offer one of the largest pool of Group Tour options across the Indian Travel industry covering a round-the-year departure calendar.<br />

                Whether you are a casual traveler, an adventure enthusiast or a business tourist, we have a group tour tailored for you. Choose your destination and leave the arrangements to us. Eliminate the stress of running around for accommodations or visas and hit the road with other passionate travelers on a budget friendly journey. At El-alpha tourism, we offer <b>guided group tours</b> to spectacular destinations for all budgets. From planning, coordinating and arranging, we ensure a memorable experience and a hassle free journey every time. Choose from over 2000 departures across the globe covering multiple countries and destinations. Ready to venture out and explore the world? Plan your next experience seamlessly with El-alpha tourism.</p>
        </div>
    )
}

export default AboutContent;