import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import "./contactUsForm.css";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa6";
import { FormGroup, FormLabel } from 'react-bootstrap';
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";


function TextControlsExample() {
 

  return (
  <div style={{display:"flex",justifyContent:"center"}}>
    <div className='FormFlex'>
      <div className='firstBox'>
        <h2 style={{fontSize:"35px"}}>Contact Information</h2>
        <p>Fill up the form and our team will get back to you within 24 hours</p>
        <p style={{ color: "red" }}><FaPhone /> +91(0)8691298009</p>
        <p style={{ color: "red" }}> <BsWhatsapp /> +91 9553844524</p>
        <p style={{ color: "red" }}><MdEmail /> info@elalphatourism.com</p>
        <div className='icons'>
        <FaFacebook />
         <FaInstagram />
         <FaTwitter />
        </div>
      </div>
      <div className='secondBox'>
        <Form>
          <FormGroup>
            <input type="text" placeholder="*Name" required />
          </FormGroup>
          <FormGroup  >
            <input type="email" placeholder="*Email" required />
          </FormGroup>
          <FormGroup>
            <input type="tel" placeholder="*Mobile" pattern="[0-9]{10}" required />
          </FormGroup>
          <Form.Select aria-label="Default select example" style={{marginBottom:"10px"}}>
            <option>*Select Destination</option>
            <option value="1">Victoria falls</option>
            <option value="2">Lusaka</option>
            <option value="3">Philiphines</option>
            <option value="1">Sydney</option>
            <option value="2">Bangkok</option>
            <option value="3">Japan</option>
            <option value="1">Brazil</option>
            <option value="2">China</option>
            <option value="3">France</option>
            <option value="1">Russia</option>
            <option value="2">Maldives</option>
            <option value="3">Argentina</option>
            <option value="3">Lisbon</option>
            <option value="1">Switzerland</option>
            <option value="2">Egypt</option>
            <option value="3">Dubai</option>
            <option value="1">Turkey</option>
            <option value="2">Thailand</option>
            <option value="3">Ukraine</option>
            <option value="3">Philiphines</option>
            <option value="1">Sydney</option>
            <option value="2">Bangkok</option>
            <option value="3">Japan</option>
            <option value="1">Brazil</option>
            <option value="2">China</option>
            <option value="3">France</option>
            <option value="1">Russia</option>
            <option value="2">Maldives</option>
            <option value="3">Argentina</option>
            <option value="3">Lisbon</option>
            <option value="1">Switzerland</option>
            <option value="2">Egypt</option>
            <option value="3">Dubai</option>
            <option value="1">Turkey</option>
            <option value="2">Thailand</option>
            <option value="3">Ukraine</option>
          </Form.Select>
          <FormGroup>
            <FormLabel>Travel Date:</FormLabel><br/>
            <input type="date"  required />
            <input type="number" placeholder="*Number Of Travelers" min={"0"}  required />
          </FormGroup>
          <FormGroup >
          <input type="checkbox" style={{width:"20px"}}/><span>&nbsp; Buy Now And Pay Later</span>
      </FormGroup>
          <Button  type="submit" className='formSubmit'>
            Submit
          </Button>
        </Form>
      </div>
    </div>
    </div>
    
  );
}

export default TextControlsExample;


