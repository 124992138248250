import React from 'react'
import BasicExample from './form';
import Details from '../Home/details';

function MyAccount() {
  return (
    <>
    <div><center><h1 style={{color:"GREY"}}>SIGN IN :</h1></center></div>
    <center><BasicExample/></center>
    <br/>
    <br/>
    <Details/>
    </>
  )
}

export default MyAccount;