import React from 'react';
import "./tours.css";
import InternatinalTours from './internatinalTours';
import cardImg1 from "../Images/maldives.jpg"
import DomesticTours from './domesticToura';
import Details from '../Home/details';


const cardsData = [
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' },
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' },
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' },
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' },
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' },
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' },
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' },
  { image: cardImg1, title: 'Moscow Tour Package 5 nights', description: '5 Nights',price:' 73233 |' }
];

function ToursPage() {
  return (
    <>
    <div className='tourBc'>
      <div className='tourContent'>
       <center><h1>International And Domestic Tour Packages</h1> 
        <p>Crafted and customizable tour packages to the most delightful destinations</p>
        </center>
      </div>
    </div>
    <div>
    <h1 style={{marginLeft:"20px"}}>International Tours In Focus</h1>
        <p style={{marginLeft:"20px"}}>Reach a new horizon every day with elalpha tourism!</p>
        <hr className='hrLine' style={{marginLeft:"20px"}}/>
    </div>
    <div className="card-grid">
        {cardsData.map((card, index) => (
          <InternatinalTours
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
            price={card.price}
          />
        ))}
      </div>
      <hr/>
      <div>
    <h1 style={{marginLeft:"20px"}}>Bestselling Domestic Holiday Packages</h1>
        <p style={{marginLeft:"20px"}}>Explore best selling packages for destinations within India</p>
        <hr className='hrLine' style={{marginLeft:"20px"}}/>
    </div>
    <div className="card-grid">
        {cardsData.map((card, index) => (
          <DomesticTours
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
            price={card.price}
          />
        ))}
      </div>
      <Details/>
    </>
  )
}

export default ToursPage;


