import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./form.css";

function BasicExample() {
  return (
    <Form className='formStyle'>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address: &nbsp;</Form.Label>
       
        <Form.Control type="email" placeholder="Enter email" required />
        <br/>
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      <br/>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password: &nbsp;</Form.Label>
        <Form.Control type="password" placeholder="Password" required/>
      </Form.Group>
      <br/>
      <Button variant="primary" type="submit" style={{color:"white"}}>
        Sign In
      </Button>
    </Form>
  );
}

export default BasicExample;


