import React from 'react';
import "./contactUsPage.css"
import Details from '../Home/details';
import TextControlsExample from './contactUsForm';

function ContactUsPage() {
  return (
    <>
    <div className='cubc'>
      <div className='cucont'>
        <h1>Contact Us</h1>
      </div>
    </div>
    <TextControlsExample/>
    <Details/>
    </>
  )
}

export default ContactUsPage;

