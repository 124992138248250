import React from 'react'
import Carousel from './carousel';
import "./carousel.css";
import img1 from "../Images/bangkok.jpg";
import img2 from "../Images/camping.jpg";
import img3 from "../Images//maldives.jpg";
import Grid from './grid';
import Content from './content';


function HomePage() {

  const carouselImages = [
    {
      src: img1,
      title: 'Bangkok',
      description: 'is calling!',
      button:'Book Now'
    },
    {
      src: img2,
      title: 'Camping & Adventure',
      description: '',
      button:'See Offers'
    },
    {
      src: img3,
      title: 'Explore Maldives',
      description: '',
      button:'Explore Package'
    },
  ];

  return (
    <>
      <Carousel images={carouselImages} />
      <Grid/>
      <Content/>
    </>
  )
}
export default HomePage;