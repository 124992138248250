import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './Header/navBar';
import HomePage from './Home/homePage';
import AboutPage from './About/aboutPage';
// import ServicesPage from './Srevices/servicesPage';
// import ProjectsPage from './Projects/projectsPage';
import ContactUsPage from './ContactUs/contactUsPage';
// import CartPage from './Cart/cartPage';
import MyAccount from './MyAccount/myAccount';
import ToursPage from './Tours/tours';

function App() {

  return (
    <div>
      <BrowserRouter>
       <div > 
       {/* className="App" */}
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tours" element={<ToursPage/>}/>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="my-account" element={<MyAccount/>}/>
          {/* <Route path="/services" element={<ServicesPage/>}/>
          <Route path="/projects" element={<ProjectsPage/>}/>
          <Route path="cart" element={<CartPage/>}/> */}
        </Routes>
      </div>
      </BrowserRouter>
    </div>
  );
}

export default App;


